<template lang="pug">
component(:is="tag").smooth-reflow
  slot
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

export default {
  name: 'SmoothReflow',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.$smoothReflow(this.options)
  }
}
</script>

<style lang="sass">
.smooth-reflow
  width: 100%
</style>
