export const state = () => ({
  current: {}
})

export const mutations = {
  set (state, payload) {
    state.current = payload
  },
  unset (state) {
    state.current = {}
  }
}
