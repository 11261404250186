import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd83926c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _02f0d5c2 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _3aec469a = () => interopDefault(import('../pages/room/index.vue' /* webpackChunkName: "pages/room/index" */))
const _16659478 = () => interopDefault(import('../pages/user/confirm.vue' /* webpackChunkName: "pages/user/confirm" */))
const _50e30d1b = () => interopDefault(import('../pages/user/forgot.vue' /* webpackChunkName: "pages/user/forgot" */))
const _4ba1b0fc = () => interopDefault(import('../pages/room/_id.vue' /* webpackChunkName: "pages/room/_id" */))
const _7d224940 = () => interopDefault(import('../pages/room/_id/settings.vue' /* webpackChunkName: "pages/room/_id/settings" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/faq",
    component: _fd83926c,
    name: "faq"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/register",
    component: _02f0d5c2,
    name: "register"
  }, {
    path: "/room",
    component: _3aec469a,
    name: "room"
  }, {
    path: "/user/confirm",
    component: _16659478,
    name: "user-confirm"
  }, {
    path: "/user/forgot",
    component: _50e30d1b,
    name: "user-forgot"
  }, {
    path: "/room/:id",
    component: _4ba1b0fc,
    name: "room-id",
    children: [{
      path: "settings",
      component: _7d224940,
      name: "room-id-settings"
    }]
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
