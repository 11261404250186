import Bugsnag from '@bugsnag/js'
import parseCookie from '~/util/cookie'

// export const plugin = [VuexPersist]

export const state = () => ({
  token: '',
  join_room: ''
})

export const mutations = {
  token (state, payload) {
    state.token = payload.token || ''
  },
  removeToken (state) {
    state.token = ''
  },
  setJoinRoom (state, payload) {
    state.join_room = payload
  },
  resetJoinRoom (state) {
    state.join_room = ''
  }
}

export const getters = {
  authenticated: state => state.token.length > 0,
  room: state => state.join_room.length > 0 ? state.join_room : false
}

export const actions = {
  async nuxtServerInit ({ commit }, { req, $axios, query, route }) {
    const cookieState = parseCookie(req.headers.cookie)

    if (cookieState && cookieState.token) {
      commit('token', { token: cookieState.token })
    }

    // Get user
    const user = await $axios.$get('/person').catch((e) => {
      Bugsnag.notify(e)
      return {}
    })
    commit('user/set', user)

    // Set room if user got invite link
    if (query.join) {
      commit('setJoinRoom', query.join)
    }
  },
  logout ({ commit }) {
    commit('removeToken')
    commit('user/unset')
  }
}
