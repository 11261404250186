<template lang="pug">
transition(name="popup" appear :duration="300")
  .popup-content-container(v-if="show")
    v-card.multi-control.mb-2(v-if="isMulti")
      .card-inner.pa-2.d-flex.justify-space-between.align-center
        v-btn(icon small @click="prev" :disabled="!isNotFirstSlide" color="primary")
          v-icon(small) mdi-chevron-left
        span.mx-2.subtitle-2.font-weight-medium {{ people.length }} persons
        v-btn(icon small @click="next" :disabled="!isNotLastSlide" color="primary")
          v-icon(small) mdi-chevron-right
    v-window(v-model="slide")
      v-window-item(v-for="(person, i) in people" :key="i")
        v-card
          .card-inner
            smooth-reflow.d-flex.flex-column
              v-card-text.d-flex.align-center.upper-content
                v-icon mdi-account
                .d-flex.flex-column.align-start.pl-4.pr-6.flex-grow-1.flex-shrink-1
                  span.lineheight-1.font-weight-medium.primary--text {{ person.name.full }}
                  span.lineheight-1.subtitle-2.text--secondary {{ data.place_name }}
                v-btn(icon color="primary" small @click="toggleLowerContent")
                  v-icon.turnable(:class="{ turned: opened }") mdi-chevron-right
              .flex-column(v-show="opened")
                v-divider(inset)
                v-btn(text height="48px" v-if="person.phone" :href="phoneLink").list-item
                  v-icon(small).ml-1 mdi-phone
                  span.pl-4.font-weight-medium.text-none.body-2 {{ person.phone }}
                v-btn(text height="48px" :href="emailLink").list-item
                  v-icon(small).ml-1 mdi-email
                  span.d-inline-block.pl-4.font-weight-medium.text-none.body-2.text-truncate {{ person.email }}
</template>

<script>
import { map } from 'lodash'
import { MglPopup } from 'vue-mapbox'
import SmoothReflow from '~/components/SmoothReflow'

export default {
  components: { MglPopup, SmoothReflow },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      opened: false,
      show: false,
      slide: 0
    }
  },
  computed: {
    people () {
      return map(this.data.persons, (p) => {
        const n = p.name
        const nameArray = this.infix && this.infix !== '' ? [n.first, n.infix, n.last] : [n.first, n.last]
        p.name.full = nameArray.join(' ')
        return p
      })
    },
    isMulti () {
      return this.data.persons.length > 1
    },
    phoneLink () {
      return 'tel:' + this.data.phone
    },
    emailLink () {
      return 'mailto:' + this.data.email
    },
    isNotLastSlide () {
      return this.slide < this.people.length - 1
    },
    isNotFirstSlide () {
      return this.slide > 0
    }
  },
  methods: {
    toggleLowerContent (e) {
      this.opened = !this.opened
    },
    showPopup () {
      this.show = true
    },
    hidePopup () {
      this.show = false
    },
    next () {
      if (this.isNotLastSlide) {
        this.slide++
      }
    },
    prev () {
      if (this.isNotFirstSlide) {
        this.slide--
      }
    }
  }
}
</script>

<style lang="sass">
.mgl-map-wrapper
  .mapboxgl-popup
    max-width: 320px !important
    overflow: hidden
    padding: 0 4px
    box-sizing: content-box

    .mapboxgl-popup-tip
      height: 0
      width: 0
      display: none

    .mapboxgl-popup-content
      background: none
      padding: 0
      box-shadow: none
      margin-bottom: 30px
      width: auto

      .v-card
        width: 320px
        overflow: hidden

        &.multi-control
          width: auto

        .v-divider--inset
          margin-left: 16px
          max-width: calc(100% - 32px)

        &__text
          width: auto
          font-size: 1rem
          line-height: 1.375

      .v-btn.list-item
        border-radius: 16px
        text-align: left
        justify-content: flex-start
        width: 100%

        span
          max-width: 100%

      .v-icon.turnable
        transition: all 300ms ease-in-out

        &.turned
          transform: rotateZ(90deg)

.mapboxgl-marker
  cursor: pointer

.popup-content-container
  &.popup
    &-enter-active, &-leave-active
      .v-card
        transition: transform 300ms cubic-bezier(0.16, 1, 0.3, 1)
        transform: scale(1)
        transform-origin: bottom center

        .card-inner
          opacity: 1
          transition: all 300ms ease-in-out 150ms

    &-enter, &-leave-to
      .v-card
        transform: scale(0)

        .card-inner
          opacity: 0
</style>
