<template lang="pug">
  .marker(:class="{ cluster: isCluster }")
   span.pointCount(v-if="isCluster") {{ point_count }}
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    point_count: {
      type: Number,
      default: 0
    },
    isCluster: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.marker
  background-color: #16215C
  width: 32px
  height: 32px
  border-radius: 50%
  cursor: pointer
  box-shadow: 0 2px 2px 0 rgba(#16215C,.28), 0 3px 1px -2px rgba(#16215C,.24), 0 1px 5px 0 rgba(#16215C,.4)
  display: flex
  justify-content: center
  align-items: center

  .pointCount
    font-size: 12px
    font-weight: bold
    color: white

  &.cluster
    background-color: #E7755F
    width: 48px
    height: 48px
    box-shadow: 0 2px 2px 0 rgba(#E7755F,.28), 0 3px 1px -2px rgba(#E7755F,.24), 0 1px 5px 0 rgba(#E7755F,.4)

    .pointCount
      font-size: 16px
</style>
