<template lang="pug">
header.nav-header.py-md-8.px-md-16.pa-2.d-flex.justify-space-between.align-center(:class="{ compact: compact }")
  nuxt-link(to="/").d-none.d-md-flex
    logo.logo
  v-btn(fab fixed top left text v-if="opened" @click="closeNav").d-flex.d-md-none
    v-icon.text--primary mdi-close
  v-btn(fab fixed top left :text="!compact" v-else @click="openNav").d-flex.d-md-none
    v-icon.text--primary mdi-menu
  transition(name="menu-translate")
    nav.d-flex.align-center.flex-md-row.flex-column.pa-md-0.pa-8.justify-center(v-if="showMenu")
      nuxt-link(to="/" v-if="isMobileNav" @click="closeNav").nav-link Home
      nuxt-link(to="/faq" @click="closeNav").nav-link FAQ
      nuxt-link(to="/about" @click="closeNav").nav-link About
      nuxt-link(to="/room" v-if="authenticated" @click="closeNav").nav-link.inversed Maps
      nuxt-link(to="/login" v-else-if="showSignIn" @click="closeNav").nav-link.inversed Sign in
</template>

<script>
import Logo from '~/assets/svg/logotext_lp.svg?inline'

export default {
  components: { Logo },
  props: {
    offset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      opened: false
    }
  },
  computed: {
    authenticated () {
      return this.$store.getters.authenticated
    },
    showMenu () {
      return this.opened || !this.isMobileNav
    },
    isMobileNav () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },
    showSignIn () {
      return this.$route.path !== '/register'
    },
    compact () {
      return this.offset > 50
    }
  },
  methods: {
    openNav () {
      this.opened = true
    },
    closeNav () {
      this.opened = false
    }
  }
}
</script>

<style lang="sass" scoped>
header
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100vw
  z-index: 4
  box-sizing: border-box
  height: 108px
  transition: all 400ms cubic-bezier(0.76, 0, 0.24, 1), background 250ms ease-in-out !important

  @media screen and (min-width: 960px)
    &.compact
      background: white
      padding: 16px 8px !important
      height: auto
      transition: all 400ms cubic-bezier(0.76, 0, 0.24, 1)

      .logo
        height: 28px
        padding-left: 24px
        transition: all 400ms cubic-bezier(0.76, 0, 0.24, 1)

  .logo
    height: 36px
    cursor: pointer
    transition: all 400ms cubic-bezier(0.76, 0, 0.24, 1)

    g, rect, path
      transition: all 400ms cubic-bezier(0.76, 0, 0.24, 1)
      fill: #16215C

    &:hover
      g, rect, path
        fill: #F8A658

  .nav-link
    display: block
    margin: 0 32px
    font-weight: bold
    text-decoration: none
    font-size: 1.25rem
    padding-bottom: .25rem
    position: relative

    &::after
      content: ""
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 3px
      transform-origin: right
      transform: scaleX(0)
      transition: transform .75s cubic-bezier(.19,1,.22,1)
      background-color: #F8A658

    &.nuxt-link-exact-active
      display: none

    &.inversed
      color: #E7755F

    &:hover
      &::after
        transform-origin: left
        transform: scaleX(1)

  @media screen and (max-width: 959px)
    z-index: 999

    nav
      position: fixed
      top: 0
      left: 0
      width: 100vw
      height: 100vh
      background: white

      .nav-link
        margin: 16px 0
        font-size: 3rem

  @media screen and (max-width: 599px)
    nav
      .nav-link
        margin: 8px 0
        font-size: 2rem

</style>
