import parseCookie from '~/util/cookie'

export default ({ $axios, store, req }) => {
  $axios.onRequest((config) => {
    const { token } = process.server && req ? parseCookie(req.headers.cookie) : store.state

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
  })
}
