import qs from 'qs'

export default function ({ $axios, $config }, inject) {
  const gmaps = $axios.create({
    baseURL: $config.BASE_URL + '/api/maps',
    paramsSerializer: qs.stringify
  })

  inject('gmaps', gmaps)
}
