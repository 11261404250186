<template lang="pug">
v-app
  v-main
    v-container.fill-height(fluid).pa-0
      .error-page.d-flex.flex-column.align-center.justify-center
        nav-header
        login-backdrop
        h1.font-weight-bold.text-h5.z2 {{ heading }}
        v-btn.font-weight-bold.mt-2(text color="accent" nuxt to="/" x-large) Go home
</template>

<script>
import LoginBackdrop from '~/components/LoginBackdrop.vue'
import NavHeader from '~/components/NavHeader.vue'

export default {
  layout: 'empty',
  transition: 'fade-translate',
  components: { NavHeader, LoginBackdrop },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return this.$seo({ title })
  },
  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  computed: {
    heading () {
      return this.error.statusCode === 404 ? 'I don\'t think you\'re gonna find something here.' : 'Looks like you broke something, but don\'t worry, the admin is notified.'
    }
  }
}
</script>

<style lang="sass">
@import "~assets/sass/main.sass"

.z2
  z-index: 2

.error-page
  max-height: 100vh
  height: 100vh
  width: 100vw
</style>
