import Bugsnag from '@bugsnag/js'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import { key } from '~/util/cookie'

const ExpressCookies = Cookies.withConverter({
  write (value) {
    // Prepend j: prefix if it is JSON object
    try {
      const tmp = JSON.parse(value)
      if (typeof tmp !== 'object') {
        throw new TypeError('Not type of Object')
      }
      value = 'j:' + JSON.stringify(tmp)
    } catch (e) {
      Bugsnag.notify(e)
    }

    return value
  },
  read (value) {
    value = Cookies.converter.read(value)
    // Check if the value contains j: prefix otherwise return as is
    return value.slice(0, 2) === 'j:' ? value.slice(2) : value
  }
})

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      key,
      restoreState: (key, storage) => ExpressCookies.get(key),
      reducer: state => ({ token: state.token }),
      saveState: (key, state, storage) => ExpressCookies.set(key, state, { expires: 365 }),
      filter: mutation => mutation.type === 'removeToken' || (mutation.type === 'token' && mutation.payload.keep)
    }).plugin(store)
  })
}
