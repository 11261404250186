import { some } from 'lodash'
import parseCookie from '~/util/cookie'

const privatePaths = ['/room/*']
const actionPaths = ['/login', '/register', '/user/confirm']

export default function ({ store, redirect, route, req, query }) {
  const isPrivateRoute = some(privatePaths, regex => RegExp(regex).test(route.path))
  const isPublicActionRoute = some(actionPaths, regex => RegExp(regex).test(route.path))

  let authenticated = store.getters.authenticated

  // Serverside handling by nuxt. This will result in
  // the token not being set (before this middleware) which
  // results in a 'false' value. Therefore, parse the cookie
  // to get that token first
  if (process.server && req) {
    const cookieState = parseCookie(req.headers.cookie)
    authenticated = Boolean(cookieState.token)
  }

  // User is in protected route & not authenticated
  if (!authenticated && isPrivateRoute) {
    return redirect('/login', query)
  }

  // User is logged-in and goes to action paths which are only
  // meant for new (not logged-in) users.
  if (authenticated && isPublicActionRoute) {
    return redirect('/room', query)
  }
}
