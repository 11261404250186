<template lang="pug">
  mgl-map(:accessToken="accessToken" :mapStyle.sync="mapStyle" @load="onMapLoaded" maxZoom.sync="12" :doubleClickZoom="false" :mapboxGl="mapbox" :bearing.sync="rotation" :pitch.sync="pitch" maxPitch="45")
    mgl-scale-control(position="bottom-left")
    mgl-navigation-control(position="bottom-left")
    mgl-geojson-layer(:source="mainSource" sourceId="clusters" layerId="pointsLayer" :layer="pointsLayer")
</template>

<script>
import Vue from 'vue'
import Mapbox from 'mapbox-gl'
import Bugsnag from '@bugsnag/js'
import { MglMap, MglNavigationControl, MglScaleControl, MglGeojsonLayer, MglMarker } from 'vue-mapbox'
import MapPopup from '~/components/MapPopup.vue'
import MapMarker from '~/components/MapMarker.vue'

export default {
  components: { MglMap, MglNavigationControl, MglScaleControl, MglGeojsonLayer, MapPopup, MglMarker },
  props: {
    mapData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      accessToken: 'pk.eyJ1Ijoic3ZlbndzdHJsIiwiYSI6ImNrN3N6cmV3ZjBveTYzZXFyZ2JuYnFhNGUifQ.B7hei7A6tIV-6ydvy-Ho8g',
      mapStyle: 'mapbox://styles/svenwstrl/ck81k1k741ybr1iofnm9yw7qi',
      rotation: 0,
      pitch: 0
    }
  },
  computed: {
    mainSource () {
      return {
        type: 'geojson',
        data: this.mapData,
        cluster: true,
        clusterRadius: 8
      }
    },
    pointsLayer () {
      return {
        type: 'circle',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 0
        }
      }
    }
  },
  watch: {
    rotation (val) {
      this.emitNewRotation(val, this.pitch)
    },
    pitch (val) {
      this.emitNewRotation(this.rotation, val)
    }
  },
  created () {
    this.mapbox = Mapbox
    this.$map = null
    this.markers = {}
    this.markersOnScreen = {}
  },
  methods: {
    emitNewRotation (rot, pitch) {
      this.$emit('rotationChanged', { rotation: 360 - rot, pitch })
    },
    onMapLoaded ({ map }) {
      this.$map = map
      this.$map.setMaxPitch(45)

      this.$map.on('data', (e) => {
        if (e.sourceId === 'clusters' && e.isSourceLoaded) {
          this.$map.on('move', this.updateMarkers)
          this.$map.on('moveend', this.updateMarkers)
          this.updateMarkers()
        }
      })
    },
    onClusterClick (clusterId, coords) {
      this.$map.getSource('clusters').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (!err) {
            this.$map.once('moveend', () => {
              const markerToDelete = this.markersOnScreen[clusterId]

              if (markerToDelete) {
                markerToDelete.remove()
              }
            })

            this.$map.flyTo({ center: coords, zoom: zoom + 1 })
          } else {
            Bugsnag.notify(err)
          }
        }
      )
    },
    onPointDubbleClick (properties, coordinates) {
      this.$map.flyTo({ center: coordinates, zoom: 14, essential: true })
    },
    updateMarkers () {
      const newMarkers = {}
      const features = this.$map.querySourceFeatures('clusters')

      features.forEach((feature) => {
        const coords = feature.geometry.coordinates
        const props = feature.properties

        const isCluster = Boolean(props.cluster)
        const id = isCluster ? props.cluster_id : props.id

        let marker = this.markers[id]

        if (!marker) {
          marker = this.createMarker(props, id, coords, isCluster)

          if (!isCluster) {
            const persons = JSON.parse(props.persons)
            const data = { ...props, persons, coordinates: coords }
            const popup = this.createPopup(data)
            marker.setPopup(popup)
          }

          this.markers[id] = marker
        }

        newMarkers[id] = marker

        if (!this.markersOnScreen[id]) {
          marker.addTo(this.$map)
        }
      })

      for (const id in this.markersOnScreen) {
        if (!newMarkers[id]) {
          this.markersOnScreen[id].remove()
        }
      }

      this.markersOnScreen = newMarkers
    },
    createMarker (props, id, coords, isCluster) {
      const propsData = { isCluster, ...props }
      const { $el } = new Vue({ ...MapMarker, parent: this, propsData }).$mount()

      const listener = isCluster ? () => this.onClusterClick(id, coords) : () => this.onPointDubbleClick(props, coords)
      $el.addEventListener(isCluster ? 'click' : 'dblclick', listener)

      return new this.mapbox.Marker($el).setLngLat(coords)
    },
    createPopup (data) {
      const popupContent = new Vue({ ...MapPopup, parent: this, propsData: { data } }).$mount()

      const popup = new this.mapbox.Popup({ maxWidth: '320px', anchor: 'bottom', closeButton: false })
        .setDOMContent(popupContent.$el)
        .on('close', popupContent.hidePopup)
        .on('open', popupContent.showPopup)

      return popup
    },
    removeMarkerAfterMove (id) {
      this.markersOnScreen[id].remove()
    }
  }
}
</script>
