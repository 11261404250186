import Bugsnag from '@bugsnag/js'

export const key = 'm_auth'

export default (cookie) => {
  if (cookie) {
    const authIndex = cookie.indexOf(`${key}=`)

    if (authIndex > -1) {
      try {
        return JSON.parse(cookie.substring(key.length + 3))
      } catch (e) {
        Bugsnag.notify(e)
      }
    }
  }

  return false
}
