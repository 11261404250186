<template lang="pug">
v-app
  v-main
    v-container.fill-height(fluid).pa-0
      nuxt
      client-only
        cookie-law(buttonClass="v-btn v-btn--contained v-btn--depressed theme--light v-size--large primary")
          div(slot="message") This website uses funtional cookies to provide you the best experience.
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'

export default {
  middleware: ['auth'],
  head () {
    return this.$seo({
      title: 'Connecting people around the globe'
    })
  }
}
</script>

<style lang="sass">
@import "~assets/sass/main.sass"

.page
  max-height: 100vh
  height: 100vh
  width: 100%

.Cookie--base
  z-index: 9999 !important
  background: white !important
</style>
